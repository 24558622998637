// extracted by mini-css-extract-plugin
export var bodyContent = "Studio-module--body-content--53ff7";
export var contactBlock = "Studio-module--contact-block--82be6";
export var header1 = "Studio-module--header1--8796f";
export var header2 = "Studio-module--header2--44331";
export var header3 = "Studio-module--header3--85a94";
export var header4 = "Studio-module--header4--f7108";
export var header5 = "Studio-module--header5--2f020";
export var imagesWrapper = "Studio-module--images-wrapper--0e8e3";
export var info = "Studio-module--info--1e071";
export var infoAdmin = "Studio-module--info-admin--f5be2";
export var infoClients = "Studio-module--info-clients--fd55c";
export var studioImageBlock = "Studio-module--studio-image-block--cf583";