import React, { useEffect, useContext, useMemo } from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Wrapper } from "../components/layout/Wrapper"
import { AVCOContext } from "../components/context/AVCOContext"
import SanityBlockContent from "@sanity/block-content-to-react"
import { PageBlock } from "../components/pagecomponents/Project/PageBlock"
import { Container } from "../components/layout/Container"
import Seo from "../components/common/seo"
import { blocksToContent } from "../utils/content"
import { ContactInfo } from "../components/pagecomponents/ContactInfo"

import ReactMarkdown from "react-markdown"
import { ImageBlock } from "../components/common/ImageBlock"

import * as styles from "../styles/pages/Studio.module.scss"

export const query = graphql`
  query StudioPageQuery {
    homepage: sanitySingletonHomepage {
      contact {
        name
        telephone
        email
        _rawAddress(resolveReferences: { maxDepth: 10 })
      }
    }

    studiopage: sanitySingletonStudioPage {
      _rawBody(resolveReferences: { maxDepth: 10 })
      _rawAvcoInformation(resolveReferences: { maxDepth: 10 })
      _rawAvcoClients(resolveReferences: { maxDepth: 10 })
      pageBlocks {
        blocks {
          ... on SanityHostedMediaDisplay {
            _key
            _type
            altText
            format
            mediaDescription
            mediaUrl
            title
          }
          ... on SanityImagesBlock {
            _key
            _type
            description
            format
            images {
              altText
              caption
              image {
                asset {
                  gatsbyImageData(width: 1920)
                }
              }
            }
          }
          ... on SanityMp4SBlock {
            _key
            _type
            format
            mp4Videos {
              altText
              mediaDescription
              mp4Upload {
                source {
                  asset {
                    url
                  }
                }
              }
              placeholderImage {
                asset {
                  gatsbyImageData(width: 1920)
                }
              }
            }
          }
          ... on SanityTextBlock {
            _key
            _type
            _rawTextArea(resolveReferences: { maxDepth: 10 })
          }
        }
      }
    }
  }
`

const StudioPage = ({ data }) => {
  const contact = data.homepage.contact
  const body = data.studiopage._rawBody
  const clients = data.studiopage._rawAvcoClients
  const info = data.studiopage._rawAvcoInformation
  const images = data.studiopage.avcoimages
  const pageBlocks = data.studiopage.pageBlocks

  const { activeMenuItem, setActiveMenuItem } = useContext(AVCOContext)

  useEffect(() => {
    setActiveMenuItem("studio")
  }, [activeMenuItem, setActiveMenuItem])

  const splitBlocks = useMemo(() => {
    return blocksToContent(pageBlocks)
  }, [pageBlocks])

  return (
    <>
      <Seo title="AVCO Studio" />

      <Wrapper className={styles.bodyContent} narrow>
        <div>
          <SanityBlockContent blocks={body} />
        </div>
      </Wrapper>

      <Wrapper narrow>
        <ContactInfo {...contact} className={styles.contactBlock} />
      </Wrapper>
      {/* {images && (
        <div className={styles.imagesWrapper}>
          {images.map((image, index) => {
            return (
              <ImageBlock
                format={image.Format}
                images={image.images}
                description={image.description}
                className={styles.studioImageBlock}
                noPadding
              />
            )
          })}
        </div>
      )} */}

      {/* Page Blocks */}
      {splitBlocks.length > 0 && (
        <Container className={styles.whiteBackground} padded={false}>
          {splitBlocks.map(({ type, portableText, ...rest }, idx) => (
            <PageBlock key={idx} type={type} {...rest}>
              {type === "portableText" && (
                <SanityBlockContent blocks={portableText} />
              )}
            </PageBlock>
          ))}
        </Container>
      )}

      <Wrapper className={styles.info} narrow>
        <div className={styles.infoClients}>
          <SanityBlockContent blocks={clients} />
        </div>
        <div className={styles.infoAdmin}>
          <SanityBlockContent blocks={info} />
        </div>
      </Wrapper>
    </>
  )
}

export default StudioPage
